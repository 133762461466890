html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

body,html,#root {
  	width: 100%;
  	height: 100%;
}
#YSF-BTN-HOLDER {
	display: none !important
}
.style_headNavbar__1EbSi {
  width: 100%;
  height: 70px;
  line-height: 70px;
  background: white;
  position: fixed;
  top: 0;
  z-index: 999; }
  .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA {
    width: 1192px;
    margin: 0 auto;
    position: relative; }
    .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarLogo__3WGVJ {
      display: inline-block;
      vertical-align: top;
      width: 90px;
      height: 28px;
      margin-left: 0px;
      padding-top: 21px; }
      .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarLogo__3WGVJ img {
        width: 90px;
        height: 28px;
        display: block; }
    .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarList__2jTMI {
      display: inline-block;
      vertical-align: top;
      height: 70px;
      line-height: 70px;
      font-size: 0; }
      .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarList__2jTMI .style_navbarCell__3OWUM {
        display: inline-block;
        vertical-align: top;
        font-size: 16px;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        color: #686871;
        cursor: pointer; }
        .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarList__2jTMI .style_navbarCell__3OWUM:nth-child(1) {
          margin-left: 87px; }
        .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarList__2jTMI .style_navbarCell__3OWUM:nth-child(2) {
          margin-left: 40px; }
        .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarList__2jTMI .style_navbarCell__3OWUM:nth-child(3) {
          margin-left: 40px; }
        .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarList__2jTMI .style_navbarCell__3OWUM:nth-child(4) {
          margin-left: 36px; }
      .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarList__2jTMI .style_active__1Wip5 {
        font-size: 18px;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        color: #33333d; }
    .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarSearch__3PT33 {
      display: inline-block;
      vertical-align: top;
      font-size: 0;
      line-height: 1;
      width: 376px;
      height: 36px;
      border: 1px solid #df3c2b;
      border-radius: 8px;
      margin-top: 17px;
      margin-left: 294px;
      box-sizing: border-box;
      position: relative; }
      .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarSearch__3PT33 .style_navbarSort__2Iol1 {
        display: inline-block;
        vertical-align: top;
        font-size: 0;
        line-height: 1;
        height: 100%;
        background: #fff;
        border-radius: 8px;
        cursor: pointer; }
        .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarSearch__3PT33 .style_navbarSort__2Iol1 .style_sortName__P9sxb {
          display: inline-block;
          vertical-align: top;
          width: 70px;
          height: 14px;
          font-size: 14px;
          font-family: PingFangSC-Regular;
          font-weight: 400;
          color: #999999;
          line-height: 36px;
          margin-left: 11px;
          text-align: center; }
        .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarSearch__3PT33 .style_navbarSort__2Iol1 .style_sortSign__1Aie- {
          display: inline-block;
          vertical-align: top;
          width: 12px;
          height: 12px;
          margin-left: 7px;
          margin-top: 13px; }
          .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarSearch__3PT33 .style_navbarSort__2Iol1 .style_sortSign__1Aie- img {
            width: 100%;
            height: 100%;
            display: block; }
      .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarSearch__3PT33 .style_navbarDivide__2YvAO {
        display: inline-block;
        vertical-align: top;
        height: 100%;
        background: #fff;
        font-size: 0;
        line-height: 1; }
        .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarSearch__3PT33 .style_navbarDivide__2YvAO .style_navbarLine__3f1v- {
          width: 1px;
          height: 16px;
          background: #dbdbdb;
          margin-left: 3px;
          margin-top: 10px; }
      .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarSearch__3PT33 .style_navbarInput__3hZAR {
        display: inline-block;
        vertical-align: top;
        width: 198px;
        height: 100%;
        overflow: hidden;
        font-weight: 400;
        background: #fff; }
        .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarSearch__3PT33 .style_navbarInput__3hZAR .style_navbarInputBox__VGhvD {
          height: 100%;
          display: flex;
          align-items: center; }
          .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarSearch__3PT33 .style_navbarInput__3hZAR .style_navbarInputBox__VGhvD input {
            width: 100%;
            height: 14px;
            overflow: hidden;
            display: block;
            font-size: 14px;
            color: #33333d;
            border: none;
            outline: none;
            margin-left: 8px; }
      .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarSearch__3PT33 .style_navbarButton__1n8ws {
        display: inline-block;
        vertical-align: top;
        width: 72px;
        height: 100%;
        font-size: 0;
        line-height: 1;
        background: -webkit-gradient(linear, right top, left top, from(#df3c2b), to(#ff885f));
        background: linear-gradient(-90deg, #df3c2b, #ff885f);
        border-radius: 0px 6px 6px 0px;
        cursor: pointer; }
        .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarSearch__3PT33 .style_navbarButton__1n8ws .style_searchIcon__3W_zd {
          display: inline-block;
          vertical-align: top;
          width: 22px;
          height: 22px;
          margin-top: 7px;
          margin-left: 4px; }
          .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarSearch__3PT33 .style_navbarButton__1n8ws .style_searchIcon__3W_zd img {
            width: 100%;
            height: 100%;
            display: block; }
        .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarSearch__3PT33 .style_navbarButton__1n8ws .style_searchText__2-Z0V {
          display: inline-block;
          vertical-align: top;
          font-size: 16px;
          font-family: PingFangSC-Regular;
          font-weight: 400;
          color: white;
          margin-top: 9px;
          margin-left: 5px; }
      .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarSearch__3PT33 .style_navbarSortList__lvXlY {
        position: absolute;
        top: 0;
        width: 104px;
        height: 104px; }
        .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarSearch__3PT33 .style_navbarSortList__lvXlY .style_navbarSortListEmpty__1vAkZ {
          width: 100%;
          height: 40px;
          z-index: 999;
          opacity: 0; }
        .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarSearch__3PT33 .style_navbarSortList__lvXlY .style_navbarSortListBox__1fFs_ {
          z-index: 999;
          width: 100%;
          height: 64px;
          background: white;
          box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.16);
          border-radius: 8px;
          font-size: 0;
          line-height: 1;
          overflow: hidden;
          text-align: center; }
          .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarSearch__3PT33 .style_navbarSortList__lvXlY .style_navbarSortListBox__1fFs_ .style_navbarSortCell__2dVwx {
            width: 104px;
            height: 32px;
            background: white;
            font-size: 14px;
            font-family: PingFangSC-Regular;
            font-weight: 400;
            color: #999999;
            line-height: 36px;
            cursor: pointer; }
            .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarSearch__3PT33 .style_navbarSortList__lvXlY .style_navbarSortListBox__1fFs_ .style_navbarSortCell__2dVwx.style_active__1Wip5 {
              color: #df3c2b;
              background: white; }
            .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarSearch__3PT33 .style_navbarSortList__lvXlY .style_navbarSortListBox__1fFs_ .style_navbarSortCell__2dVwx:hover {
              color: #33333d;
              background: whitesmoke; }
        .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarSearch__3PT33 .style_navbarSortList__lvXlY.style_navbarSortListHide__30XrP {
          display: none; }
    .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarUnderline__3zBXW {
      position: absolute;
      bottom: 0;
      width: 32px;
      height: 3px;
      background: #df3c2b;
      margin-left: 177px;
      -webkit-transition: all 0.3s;
      transition: all 0.3s; }
      .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarUnderline__3zBXW.style_navbarUnderlineTwo__2beoQ {
        margin-left: 225px;
        -webkit-transform: translate(100%);
                transform: translate(100%); }
      .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarUnderline__3zBXW.style_navbarUnderlineThree__elchE {
        margin-left: 329px;
        -webkit-transform: translate(100%);
                transform: translate(100%); }
      .style_headNavbar__1EbSi .style_headNavbarContent__1VVTA .style_navbarUnderline__3zBXW.style_navbarUnderlineFour__3I_Qy {
        margin-left: 423px;
        -webkit-transform: translate(100%);
                transform: translate(100%); }

