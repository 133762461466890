
.headNavbar{
    width: 100%;
    height: 70px;
    line-height: 70px;
    background: rgba(255,255,255,1);
    position: fixed;
    top: 0;
    z-index: 999;
    .headNavbarContent{
        width: 1192px;
        margin: 0 auto;
        position: relative;
        .navbarLogo{
            display: inline-block;
            vertical-align: top;
            width: 90px;
            height: 28px;
            margin-left: 0px;
            padding-top: 21px;
            img{
                // width: 100%;
                // height: 100%;
                width: 90px;
                height: 28px;
                display: block;
            }
        }
        .navbarList{
            display: inline-block;
            vertical-align: top;
            height: 70px;
            line-height: 70px;
            font-size: 0;
            // line-height: 1;
            .navbarCell{
                display: inline-block;
                vertical-align: top;
                font-size: 16px;
                font-family: PingFangSC-Regular;
                font-weight: 400;
                color: rgba(104,104,113,1);
                cursor: pointer;
                &:nth-child(1){
                    margin-left: 87px;
                    // margin-top: 27px;
                    // width: 32px;
                    // height: 16px;
                }
                &:nth-child(2){
                    margin-left: 40px;
                    // margin-top: 26px;
                    // width: 48px;
                    // height: 16px;
                }
                &:nth-child(3){
                    margin-left: 40px;
                    // margin-top: 27px;
                    // width: 79px;
                    // height: 16px;
                }
                &:nth-child(4){
                    margin-left: 36px;
                    // margin-top: 25px;
                    // width: 80px;
                    // height: 17px;
                }
            }
            .active{
                font-size: 18px;
                font-family: PingFangSC-Semibold;
                font-weight: 600;
                color:rgba(51,51,61,1);
            }
        }
        .navbarSearch{
            display: inline-block;
            vertical-align: top;
            font-size: 0;
            line-height: 1;
            width: 376px;
            height: 36px;
            border: 1px solid rgba(223,60,43,1);
            border-radius: 8px;
            margin-top: 17px;
            margin-left: 294px;
            box-sizing: border-box;
            // overflow: hidden;
            position: relative;
            .navbarSort{
                display: inline-block;
                vertical-align: top;
                font-size: 0;
                line-height: 1;
                height: 100%;
                background: #fff;
                border-radius: 8px;
                cursor: pointer;
                .sortName{
                    display: inline-block;
                    vertical-align: top;
                    width: 70px;
                    height: 14px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular;
                    font-weight: 400;
                    color: rgba(153,153,153,1);
                    line-height: 36px;
                    margin-left: 11px;
                    text-align: center;
                }
                .sortSign{
                    display: inline-block;
                    vertical-align: top;
                    width: 12px;
                    height: 12px;
                    margin-left: 7px;
                    margin-top: 13px;
                    img{
                        width: 100%;
                        height: 100%;
                        display: block;
                    }
                }
                // &:hover{
                //     ~.navbarSortList{
                //         display: block;
                //     }
                // }
            }
            .navbarDivide{
                display: inline-block;
                vertical-align: top;
                height: 100%;
                background: #fff;
                font-size: 0;
                line-height: 1;
                .navbarLine{
                    width: 1px;
                    height: 16px;
                    background: rgba(219,219,219,1);
                    margin-left: 3px;
                    margin-top: 10px;          
                }
            }
            .navbarInput{
                display: inline-block;
                vertical-align: top;
                width: 198px;
                height: 100%;
                overflow: hidden;
                font-weight: 400;
                // line-height: 36px;
                background: #fff;
                .navbarInputBox{
                    height: 100%;
                    display: flex;
                    align-items: center;
                    input{
                        width: 100%;
                        height: 14px;
                        overflow: hidden;
                        display: block;
                        font-size: 14px;
                        // color: rgba(153,153,153,1);
                        color:rgba(51,51,61,1);
                        border: none;
                        // 去掉input 获取焦点时的边框
                        outline: none;
                        // margin-top: 11px;
                        margin-left: 8px;
                    }
                }
                
            }
            .navbarButton{
                display: inline-block;
                vertical-align: top;
                width: 72px;
                // height: 36px;
                height: 100%;
                font-size: 0;
                line-height: 1;
                background: linear-gradient(-90deg,rgba(223,60,43,1),rgba(255,136,95,1));
                border-radius:0px 6px 6px 0px;
                cursor: pointer;
                .searchIcon{
                    display: inline-block;
                    vertical-align: top;
                    width: 22px;
                    height: 22px;
                    margin-top: 7px;
                    margin-left: 4px;
                    img{
                        width: 100%;
                        height: 100%;
                        display: block;
                    }
                }
                .searchText{
                    display: inline-block;
                    vertical-align: top;
                    // width: 30px;
                    // height: 16px;
                    font-size: 16px;
                    font-family: PingFangSC-Regular;
                    font-weight: 400;
                    color: rgba(255,255,255,1);
                    margin-top: 9px;
                    margin-left: 5px;
                }
            }
            .navbarSortList{
                position: absolute;
                top: 0;
                width: 104px;
                height: 104px;
                // top: 40px;
                // display: none;
                .navbarSortListEmpty{
                    width: 100%;
                    height: 40px;
                    z-index: 999;
                    opacity: 0;
                }
                .navbarSortListBox{
                    z-index: 999;
                    width: 100%;
                    // width: 104px;
                    height: 64px;
                    background: rgba(255,255,255,1);
                    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.16);
                    border-radius: 8px;
                    font-size: 0;
                    line-height: 1;
                    overflow: hidden;
                    text-align: center;
                    .navbarSortCell{
                        width: 104px;
                        height: 32px;
                        // background: rgba(245,245,245,1);
                        background: rgba(255,255,255,1);
                        // border-radius: 8px 8px 0px 0px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular;
                        font-weight: 400;
                        color: rgba(153,153,153,1);
                        line-height: 36px;
                        cursor: pointer;
                        &.active{
                            color: rgba(223,60,43,1);
                            background:rgba(255,255,255,1);
                        }
                        &:hover{
                            color:rgba(51,51,61,1);
                            background: rgba(245,245,245,1);
                            // background:rgba(255,255,255,1);
                        }
                    }
                }
                &.navbarSortListHide{
                    display: none;
                }
            }
            
        }
        .navbarUnderline{
            position: absolute;
            bottom: 0;
            width: 32px;
            height: 3px;
            background: rgba(223,60,43,1);
            margin-left: 177px;
            transition: all 0.3s;
            &.navbarUnderlineTwo {
                margin-left: 225px;
                transform:translate(100%)
            }
            &.navbarUnderlineThree {
                margin-left: 329px;
                transform:translate(100%)
            }
            &.navbarUnderlineFour {
                margin-left: 423px;
                transform:translate(100%)
            }
        }
    }
    
}